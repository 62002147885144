import '@babel/polyfill';
import svg4everybody from 'svg4everybody';
import $ from 'jquery';
import 'jquery-inview';
import {gsap} from 'gsap';
import ScrollMagic from 'scrollmagic';
import {ScrollMagicPluginGsap} from 'scrollmagic-plugin-gsap';
import LazyLoad from 'vanilla-lazyload';

svg4everybody();

ScrollMagicPluginGsap(ScrollMagic, gsap);

window.$ = $;
window.jQuery = $;
window.gsap = gsap;
window.ScrollMagic = ScrollMagic;
window.LazyLoad = LazyLoad;

require('ninelines-ua-parser');

