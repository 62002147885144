import './vendor';
import vars from './helpers';
import { fancybox } from '@fancyapps/fancybox';
import 'jquery-validation';
import '../../node_modules/jquery-validation/dist/additional-methods.js';
import inputmask from 'inputmask/dist/jquery.inputmask';
// import './script';

import Swiper, {Navigation, Pagination, Lazy, Autoplay} from 'swiper';

Swiper.use([Navigation, Pagination, Lazy, Autoplay]);

const $document = $(document);

const $window = $(window);

const $html = $('html');

const $body = $('body');

if ($html.attr('lang') === 'ru') {
	jQuery.extend(jQuery.validator.messages, {
		required: 'Поле обязательно для заполнения',
		remote: 'Пожалуйста, введите правильное значение.',
		email: 'Пожалуйста, введите корректный адрес электронной почты.',
		url: 'Пожалуйста, введите корректный URL.',
		date: 'Пожалуйста, введите корректную дату.',
		dateISO: 'Пожалуйста, введите корректную дату в формате ISO.',
		number: 'Пожалуйста, введите число.',
		digits: 'Пожалуйста, вводите только цифры.',
		creditcard: 'Пожалуйста, введите правильный номер кредитной карты.',
		equalTo: 'Пожалуйста, введите такое же значение ещё раз.',
		extension: 'Пожалуйста, выберите файл с правильным расширением.',
		maxlength: $.validator.format('Пожалуйста, введите не больше {0} символов.'),
		minlength: $.validator.format('Пожалуйста, введите не меньше {0} символов.'),
		rangelength: $.validator.format('Пожалуйста, введите значение длиной от {0} до {1} символов.'),
		range: $.validator.format('Пожалуйста, введите число от {0} до {1}.'),
		max: $.validator.format('Пожалуйста, введите число, меньшее или равное {0}.'),
		min: $.validator.format('Пожалуйста, введите число, большее или равное {0}.'),
		accept: $.validator.format('Пожалуйста, выберите верный формат файла.'),
	});
}

$.validator.addMethod(
	'regex',
	function (value, element, regexp) {
		if (regexp && regexp.constructor != RegExp) {
			regexp = new RegExp(regexp[0], regexp[1]);
		} else if (regexp.global) {
			regexp.lastIndex = 0;
		}

		return this.optional(element) || regexp.test(value);
	},
	'Неверный формат',
);

let app = {
	controller: null,
	scenes: [],
	global: {
		init() {
			$('.js-goto').on('click', (e) => {
				let $target = $($(e.currentTarget).data('target') || $(e.currentTarget).attr('href'));

				if ($target.length) {
					e.preventDefault();
					window.scrollTo(0, $target.offset().top);
					$html.removeClass('is-show-menu');
				}
			});

			$document.on('click', '.js-toggle-class', (e) => {
				e.preventDefault();

				let options = $(e.currentTarget).data();

				switch (options.type) {
					case 'add':
						$html.addClass(options.class);
						break;
					case 'remove':
						$html.removeClass(options.class);
						break;
					case 'toggle':
						$html.toggleClass(options.class);
						break;
					default:
						$html.toggleClass(options.class);
						break;
				}
			});

			$('[data-fancybox]').fancybox({
				touch: false,
				autoFocus: false,
				backFocus: false,
				closeExisting: true,
				video: {
					autoStart: !vars.isMobile(),
				},
				beforeShow() {
					setTimeout(() => {
						$('.m-direction__video:visible').each((index, item) => {
							let $item = $(item);

							let $video = $(item).find('video');

							if ($video.outerWidth() < $video.outerHeight()) {
								$item.addClass('is-vertical');
							} else {
								$item.removeClass('is-vertical');
							}
						});

						if ($('.m-direction__slider:visible').length) {
							$('.m-direction__slider:visible .swiper-container')[0].swiper.slideTo(0, 0);
							$('.m-direction__slider:visible .swiper-container')[0].swiper.update();
						}
					}, 100);
				},
			});

			$('head').append(`<style type="text/css">
				html {
					--compensate-scrollbar:${(window.innerWidth - document.documentElement.clientWidth) / -2}px;
				}
				</style>`);

			$body.css({
				'--width': $window.outerWidth(),
			});
			$window.on('resize', () => {
				$body.css({
					'--width': $window.outerWidth(),
				});
			});

			let controllerResize = null;

			let controllerWidth = $window.outerWidth();

			app.controller = new ScrollMagic.Controller({
				refreshInterval: 10,
			});

			// $window.on('resize', () => {
			// 	clearTimeout(controllerResize);
			// 	if (controllerWidth !== $window.outerWidth()) {
			// 		controllerResize = setTimeout(() => {
			// 			app.scenes.forEach(scene => {
			// 				scene.refresh();
			// 			})
			// 			controllerWidth = $window.outerWidth();
			// 		}, 1500);
			// 	}
			// });

			new LazyLoad({});

			$('[data-social]').on('click', (e) => {
				dataLayer.push({
					event: 'Social',
					eventCategory: 'Social',
					eventAction: 'click',
					eventLabel: $(e.currentTarget).data('social'),
				});
			});

			$document.on('click', 'a[href^="tel:"]', (e) => {
				dataLayer.push({
					event: 'Phone',
					eventCategory: 'Phone',
					eventAction: 'click',
					eventLabel: $(e.currentTarget).text(),
				});
			});

			$window.on('scroll', () => {
				if ($window.scrollTop() > 0) {
					$html.addClass('is-show-up');
				} else {
					$html.removeClass('is-show-up');
				}
			});
		},
	},
	vw(px) {
		let base = 1440;

		if (window.outerWidth < 1025) {
			base = 375;
		}

		return px / base * $window.outerWidth();
	},
	scriptLoading(src, callback) {
		let script = document.createElement('script');
		let loaded;

		script.setAttribute('src', src);
		if (callback) {
			script.onreadystatechange = script.onload = () => {
				if (!loaded) {
					callback();
				}
				loaded = true;
			};
		}
		document.getElementsByTagName('head')[0].appendChild(script);
	},
	header: {
		disableShadow: false,
		init($header) {
			let lastScroll = 0;

			$window.on('scroll', () => {
				$header.find('.header__menu--burger a').each((index, item) => {
					const $item = $(item);
					const $target = $($item.attr('href'));

					$item.removeClass('is-active');
					if ($target.length) {
						if ($window.scrollTop() + $window.outerHeight() / 2 > $target.offset().top && $window.scrollTop() + $window.outerHeight() / 2 + 75 < $target.offset().top + $target.outerHeight()) {
							$item.addClass('is-active');
						}
					}
				});

				if ($window.scrollTop() < lastScroll && !app.header.disableShadow) {
					$header.addClass('is-show-shadow');
				} else {
					$header.removeClass('is-show-shadow');
				}

				lastScroll = $window.scrollTop();
			});
		},
	},
	support_format_webp() {
		let elem = document.createElement('canvas');

		if (!!(elem.getContext && elem.getContext('2d'))) {
			return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
		} else {
			return false;
		}
	},
	intro: {
		init($module) {
			const bg = {
				canvas: $module.find('.intro__video-bg__canvas')[0],
				ctx: $module.find('.intro__video-bg__canvas')[0].getContext('2d'),
				imageFrom: 0,
				imageTo: 120,
				imageLoaded: 0,
				fullLoad: false,
				images: [],
				width: 1440,
				height: 1440,
				canvasVisible: false,
				setupImages() {
					const path = bg.canvas.dataset.path;

					const ext = app.support_format_webp() ? 'webp' : 'png';

					for (let i = bg.imageFrom; i <= bg.imageTo; i++) {
						let image = new Image();

						image.src = `${path}likes${i}.${ext}`;

						bg.images[i] = image;

						image.onload = () => {
							if (bg.imageLoaded === 0) {
								$html.removeClass('is-preloading');
							}
							bg.imageLoaded++;
							if (bg.imageLoaded === bg.imageTo) {
								bg.fullLoad = true;
							}
						};
					}
				},
				drawBG() {
					if (app.introImageCurrent === undefined) {
						app.introImageCurrent = 0;
					} else {
						app.introImageCurrent++;
					}
					if (app.introImageCurrent > bg.imageTo || !bg.fullLoad) {
						app.introImageCurrent = 0;
					}

					bg.ctx.clearRect(0, 0, bg.canvas.width, bg.canvas.height);

					bg.ctx.drawImage(bg.images[app.introImageCurrent], 0, 0, bg.canvas.width, bg.canvas.height);
				},
				init() {
					bg.canvas.width = bg.width;
					bg.canvas.height = bg.height;

					bg.setupImages();
				},
			};

			const video = {
				canvas: $module.find('.js-canvas')[0],
				mask: $module.find('.js-mask')[0],
				ctx: $module.find('.js-canvas')[0].getContext('2d'),
				video: $('.intro-video')[0],
				width: 1197,
				height: 859,
				canvasVisible: false,
				drawMaskedVideo() {
					video.ctx.save();
					video.ctx.clearRect(0, 0, video.canvas.width, video.canvas.height);

					video.ctx.drawImage(video.video, app.vw(-200), 0, video.canvas.width + app.vw(300), video.canvas.height);

					video.ctx.globalCompositeOperation = 'destination-in';

					video.ctx.drawImage(video.mask, 0, 0, video.canvas.width, video.canvas.height);

					video.ctx.restore();
				},
				init() {
					video.canvas.width = video.width;
					video.canvas.height = video.height;

					if ($module.hasClass('intro--last')) {
						let timeout = null;

						$window.on('scroll', () => {
							clearTimeout(timeout);
							if ($window.scrollTop() >= $module.offset().top) {
								timeout = setTimeout(() => {
									$html.css('scroll-behavior', 'initial');
									app.header.disableShadow = true;
									$('html,body').animate({
										scrollTop: $window.scrollTop() - $module.offset().top,
									}, 0, () => {
										$html.css('scroll-behavior', '');
										setTimeout(() => {
											app.header.disableShadow = false;
										}, 100);
									});
								}, vars.isMobile() ? 0 : 100);
							}
						});
					}
				},
			};

			bg.init();
			video.init();

			setInterval(() => {
				if (!video.video.paused && video.canvasVisible) {
					bg.drawBG();
					video.drawMaskedVideo();
				}
			}, 1000 / 30);

			$module.on('inview', (event, isInView) => {
				if (isInView) {
					setTimeout(() => {
						bg.canvasVisible = true;
						video.canvasVisible = true;
						video.video.play();
					});
				} else {
					bg.canvasVisible = false;
					video.canvasVisible = false;
					video.video.pause();
				}
			});
		},
	},
	about: {
		init($module) {
			const $divCanvas = $module.find('.about__canvas');

			const $trigger = $module.find('.about__trigger');

			const $bg = $module.find('.about__bg');

			const $lineBottom = $module.find('.about__line-bottom');

			let visible = false;

			let updateTimeout = null;

			const setState = () => {
				$divCanvas
					.data('state', $module.find('.about__slider .swiper-slide-active').data('name'))
					.attr('data-state', $module.find('.about__slider .swiper-slide-active').data('name'));
			};

			const slider = new Swiper($module.find('.about__slider .swiper-container')[0], {
				loop: true,
				speed: 2000,
				slidesPerView: 'auto',
				spaceBetween: app.vw(40),
				centeredSlides: true,
				slideToClickedSlide: true,
				autoplay: {
					delay: 5000,
				},
				breakpoints: {
					1025: {
						spaceBetween: app.vw(64),
					},
				},
			});

			$window.on('load', () => {
				slider.update();
			});

			$window.on('resize', () => {
				clearTimeout(updateTimeout);
				updateTimeout = setTimeout(() => {
					slider.update();
				}, 1000);
			});

			slider.on('slideChange', () => {
				setTimeout(() => {
					if (visible) {
						setState();
					}
				}, 100);
			});

			let scene = null;

			let sceneInit = () => {
				scene = new ScrollMagic.Scene({
					triggerElement: $trigger[0],
					duration: window.outerHeight * (4 / 5),
					offset: 0,
					triggerHook: 1,
				}).setTween(gsap.timeline().to($bg[0], 1, {
					rotate: 0,
					skewX: 0,
					skewY: 1.09,
				}, 0).to($lineBottom[0], 1, {
					y: vars.isMobile() ? app.vw(-10) : app.vw(-40),
					rotate: 1.09,
					autoAlpha: 0,
				}, 0)).addTo(app.controller);
			};

			let sceneResize = null;

			let sceneWidth = $window.outerWidth();

			sceneInit();
			$window.on('resize', () => {
				clearTimeout(sceneResize);
				if (sceneWidth !== $window.outerWidth()) {
					sceneResize = setTimeout(() => {
						scene.destroy(true);
						gsap.set($bg, {clearProps: 'all',});
						gsap.set($lineBottom, {clearProps: 'all',});
						scene = null;
						sceneInit();
						sceneWidth = $window.outerWidth();
					}, 500);
				}
			});

			$module.on('inview', (event, isInView) => {
				if (isInView) {
					$module.addClass('is-animate-lines');
					if (!$divCanvas.data('state')) {
						setState();
					}
					visible = true;
				} else {
					$module.removeClass('is-animate-lines');
					visible = false;
				}
			});
		},
	},
	directions: {
		init($module) {
			const updatePosition = ($elements) => {
				const windowScrollTop = window.pageYOffset;

				const windowHeight = window.innerHeight;

				$elements.each((index, element) => {
					const dataParams = element.dataset;

					const level = dataParams.parallaxLevel ? dataParams.parallaxLevel : 5;

					const sign = dataParams.parallaxSign ? dataParams.parallaxSign : 1;

					const windowCenter = windowScrollTop + windowHeight / 2;

					const elementCenter = $module.offset().top + $module.outerHeight() / 2;

					const yPos = sign * ((windowCenter - elementCenter) / level);

					if (yPos > -400 && yPos < 200) {
						gsap.to(element, index / 10 + 0.4, {
							y: -yPos,
						});
					}
				});
			};

			$window.on('scroll.parallax', () => {
				updatePosition($('.directions__item'));
			});
		},
	},
	become: {
		init($module) {
			const $canvas = $module.find('.become__canvas');

			const $items = $module.find('.become__item');

			const $bg = $module.find('.become__bg');

			const $trigger = $module.find('.become__trigger');

			let $prevItem = null;

			let scenes = [];

			let sceneInit = () => {
				let scroll = 200;

				$items.each((index, item) => {
					let $item = $(item);

					if ($prevItem) {
						scenes.push(new ScrollMagic.Scene({
							triggerElement: $trigger[0],
							duration: 200,
							offset: scroll,
							triggerHook: 0.5,
						}).setTween(gsap.to($prevItem[0], 1, {
							autoAlpha: 0,
						}, 0)).addTo(app.controller).on('enter', (event) => {
							if (event.scrollDirection === 'REVERSE') {
								$canvas
									.data('state', `state-${index - 1}`)
									.attr('data-state', `state-${index - 1}`);
							}
						}));

						scroll += 200;

						scenes.push(new ScrollMagic.Scene({
							triggerElement: $trigger[0],
							duration: 200,
							offset: scroll,
							triggerHook: 0.5,
						}).setTween(gsap.from($item[0], 1, {
							autoAlpha: 0,
						}, 0)).addTo(app.controller).on('enter', (event) => {
							if (event.scrollDirection === 'FORWARD') {
								$canvas
									.data('state', `state-${index}`)
									.attr('data-state', `state-${index}`);
							}
						}));

						scroll += 200 + 200;
					}

					$prevItem = $item;
				});

				scenes.push(new ScrollMagic.Scene({
					triggerElement: $trigger[0],
					duration: scroll,
					// offset: app.vh(540),
					triggerHook: 0.5,
				}).setPin($module.find('.become-scroll')[0], {}).addTo(app.controller));

				scenes.push(new ScrollMagic.Scene({
					triggerElement: $trigger[0],
					duration: window.outerHeight / 2,
					offset: 0,
					triggerHook: 1,
				}).setTween(gsap.from($bg[0], 1, {
					rotate: 0,
					skewX: 0,
					skewY: 8,
				}, 0)).addTo(app.controller));

				scenes.push(new ScrollMagic.Scene({
					triggerElement: $trigger[0],
					duration: window.outerHeight / 2,
					offset: scroll,
					triggerHook: 0.5,
				}).setTween(gsap.to($bg[0], 1, {
					rotate: 0,
					skewX: 0,
					skewY: -4.09,
				}, 0)).addTo(app.controller));
			};

			let sceneResize = null;

			let sceneWidth = $window.outerWidth();

			sceneInit();
			$window.on('resize', () => {
				clearTimeout(sceneResize);
				if (sceneWidth !== $window.outerWidth()) {
					sceneResize = setTimeout(() => {
						scenes.forEach((scene) => {
							scene.destroy(true);
						});
						$items.each((index, item) => {
							gsap.set($(item), {clearProps: 'all',});
						});
						gsap.set($bg, {clearProps: 'all',});
						scenes = [];
						sceneInit();
						sceneWidth = $window.outerWidth();
					}, 500);
				}
			});
		},
	},
	results: {
		init($module) {
			const slider = new Swiper($module.find('.results__slider .swiper-container')[0], {
				loop: true,
				preloadImages: false,
				lazy: true,
				spaceBetween: app.vw(24),
				navigation: {
					prevEl: $module.find('.results__slider__prev')[0],
					nextEl: $module.find('.results__slider__next')[0],
				},
			});

			app.scenes.push(new ScrollMagic.Scene({
				triggerElement: $module.find('.results__trigger')[0],
				duration: $module.outerHeight() - $window.outerHeight() / 2,
				// offset: app.vh(540),
				triggerHook: 0,
			}).setPin($module.find('.results__bg')[0], {}).addTo(app.controller));

			app.scenes.push(new ScrollMagic.Scene({
				triggerElement: $module.find('.results__back-stage__trigger')[0],
				duration: window.outerHeight * 1.5,
				offset: window.outerHeight / -2,
				triggerHook: 0.5,
			}).setTween(gsap.to($module.find('.results__back-stage__border')[0], 1, {
				rotate: 0,
				skewX: 0,
				skewY: -4.09,
			}, 0)).addTo(app.controller));

			if (!vars.isMobile()) {
				app.scenes.push(new ScrollMagic.Scene({
					triggerElement: $module.find('.results__back-stage__trigger')[0],
					duration: window.outerHeight * 1.5,
					offset: window.outerHeight / -2,
					triggerHook: 0.5,
				}).setTween(gsap.to($module.find('.results__social')[0], 1, {
					rotate: -4.22,
				}, 0)).addTo(app.controller));
			}
		},
	},
	faq: {
		init($module) {
			const $items = $module.find('.faq__item');

			const $canvas = $module.find('.faq__canvas');

			$module.find('.faq__item__header').on('click', (e) => {
				const $this = $(e.currentTarget);

				const $item = $this.closest('.faq__item');

				const $content = $item.find('.faq__item__content');

				e.preventDefault();

				if ($item.hasClass('is-active')) {
					$item.removeClass('is-active');
					$content.slideUp(400);
				} else {
					const $activeItems = $items.filter('.is-active');

					$activeItems.removeClass('is-active');
					$activeItems.find('.faq__item__content').slideUp(400);
					$item.addClass('is-active');
					$content.slideDown(400);
				}
			});

			$canvas.on('inview', (event, isInView) => {
				if (isInView) {
					$canvas
						.data('state', 'on')
						.attr('data-state', 'on');
				} else {
					$canvas
						.data('state', 'off')
						.attr('data-state', 'off');
				}
			});
		},
	},
	partners: {
		init($module) {
			const slider = new Swiper($module.find('.partners__slider .swiper-container')[0], {
				slidesPerView: 1,
				// loop: true,
				navigation: {
					prevEl: $module.find('.partners__slider__prev')[0],
					nextEl: $module.find('.partners__slider__next')[0],
				},
				breakpoints: {
					1025: {
						slidesPerView: 2,
					},
				},
			});

			$module.find('.partners__tabs__list a').on('click', (e) => {
				const $this = $(e.currentTarget);

				const $target = $module.find(`.partners__tabs__item[data-tab="${$this.data('target')}"]`);

				const $slider = $target.find('.swiper-container');

				e.preventDefault();
				$module.find('.partners__tabs__list a').removeClass('is-active');
				$module.find('.partners__tabs__item').removeClass('is-active');
				$this.addClass('is-active');
				$target.addClass('is-active');
				if ($slider.length) {
					$slider[0].swiper.update();
				}
			});
		},
	},
	select: {
		init($module) {
			const $label = $module.find('.form__select__label');

			const $select = $module.find('select');

			const setLabel = () => {
				if ($select.val()) {
					$label.text($select.find(`option[value="${$select.val()}"]`).text());
					$module.addClass('is-filled');
				} else {
					$label.text($label.data('placeholder'));
					$module.removeClass('is-filled');
				}
			};

			$select.on('change', setLabel);

			setLabel();
		},
	},
	mask: {
		init($mask) {
			$mask.inputmask($mask.data('mask').toString(), {
				showMaskOnHover: false,
				clearIncomplete: true,
			});
		},
	},
	file: {
		init($module) {
			const $list = $module.find('.form__file__list');

			const $template = $module.find('.form__file__template');

			const max = parseInt($module.data('max'), 10);

			const addField = () => {
				if ($list.find('.form__file__item').length < max) {
					$list.append($template.html());

					if ($module.find('.form__file__item.is-active').length) {
						$module.find('.form__file__item:not(.is-active) input').prop('required', false);
					} else {
						$module.find('.form__file__item:not(.is-active) input').prop('required', true);
					}
				}
			};

			$module.on('change', '.form__file__item__upload input', (e) => {
				let $this = $(e.currentTarget);

				let $item = $this.closest('.form__file__item');

				let $error = $item.find('.form__file__item__error');

				let validate = $this.data('validate');

				let error = {
					status: false,
					type: '',
				};

				if (e.target.files.length) {
					const nameSplit = e.target.files[0].name.split('.');

					if (e.target.files[0].size > validate.size * 1000000) {
						error.status = true;
						error.type = 'size';
					}

					if ($this.attr('accept').split(',').indexOf(`.${nameSplit[nameSplit.length - 1].toLowerCase()}`) === -1) {
						error.status = true;
						error.type = 'accept';
					}

					if (error.status === true) {
						$error.text(validate.messages[error.type]);
						$item.addClass('is-error');
						$this.val('');
					} else {
						$item.removeClass('is-error');
						$error.text('');
						$item.find('.form__file__item__name').text(e.target.files[0].name);
						$item.addClass('is-active');
						addField();
					}
				} else {
					$item.removeClass('is-active');
				}
			});

			$module.on('click', '.form__file__item__remove', (e) => {
				e.preventDefault();
				$(e.currentTarget).closest('.form__file__item').remove();

				if ($module.find('.form__file__item:not(.is-active)').length === 0) {
					addField();
				} else if ($module.find('.form__file__item.is-active').length) {
					$module.find('.form__file__item:not(.is-active) input').prop('required', false);
				} else {
					$module.find('.form__file__item:not(.is-active) input').prop('required', true);
				}
			});
		},
	},
	form: {
		init($form) {
			let disableSend = false;

			const validator = $form.validate({
				lang: 'ru',
				rules: {},
				highlight(element, errorClass, validClass) {
					$(element).addClass(errorClass).removeClass(validClass);
					$(element).closest('.form__file__item').addClass(errorClass).removeClass(validClass);
				},
				unhighlight(element, errorClass, validClass) {
					$(element).removeClass(errorClass).addClass(validClass);
					$(element).closest('.form__file__item').removeClass(errorClass).addClass(validClass);
				},
				submitHandler(form) {
					if (!disableSend) {
						disableSend = true;
						if ($form.data('type') === 'submit') {
							form.submit();
						} else {
							let preparedData;
							let processData;
							let contentType;

							if ($form.find('input[type="file"]').length > 0) {
								preparedData = new FormData($form.get()[0]);
								processData = false;
								contentType = false;
							}

							$form.removeClass('is-sended is-error');
							$form.addClass('is-loading');

							$.ajax({
								type: $form.attr('method'),
								url: $form.attr('action'),
								data: preparedData ? preparedData : $form.serialize(),
								dataType: 'json',
								processData,
								contentType,
								success(result) {
									if (result.result === true) {
										$form[0].reset();
										$form.find('.form__file__item.is-active').remove();
										$form.find('.form__file__item input').prop('required', true);
										$.fancybox.close();
									}
									$.fancybox.open(`<div class="modal modal--success">
										<div class="form__head">
											<div class="form__title">Записаться на кастинг</div>
											<div class="form__description">Мы будем рады видеть Вас в&nbsp;нашей Команде!</div>
										</div>
										<div class="form__head">
											<div class="modal__text">${result.message}</div>
										</div>
									</div>`, {
										touch: false,
										autoFocus: false,
									});
									$form.removeClass('is-loading');
									initApps();
									disableSend = false;
								},
							});
						}
						if (typeof dataLayer !== 'undefined' && $form.data('event')) {
							dataLayer.push({
								event: $form.data('event'),
								eventCategory: $form.data('event'),
								eventAction: 'send',
							});
						}
					}
				},
			});

			setTimeout(() => {
				$form.find('[data-validation]').each((index, item) => {
					$(item).rules('add', $(item).data('validation'));
				});
			}, 1000);
		},
	},
	mDirection: {
		init($module) {
			$module.find('.m-direction__video video').on('play', (e) => {
				let $this = $(e.currentTarget);

				$this.closest('.m-direction__video').addClass('is-play');
			});

			$module.find('.m-direction__video video').on('pause ended', (e) => {
				let $this = $(e.currentTarget);

				$this.closest('.m-direction__video').removeClass('is-play');
			});

			$module.find('.m-direction__video__play').on('click', (e) => {
				let $this = $(e.currentTarget);

				$this.closest('.m-direction__video').find('video')[0].play();
			});

			$module.find('.m-direction__video__pause').on('click', (e) => {
				let $this = $(e.currentTarget);

				$this.closest('.m-direction__video').find('video')[0].pause();
			});

			if ($module.find('.m-direction__slider')) {
				const slider = new Swiper($module.find('.swiper-container')[0], {
					spaceBetween: 20,
					autoHeight: true,
					navigation: {
						prevEl: $module.find('.m-direction__slider__prev')[0],
						nextEl: $module.find('.m-direction__slider__next')[0],
					},
				});

				slider.on('slideChange', () => {
					$module.find('.m-direction__video').each((index, item) => {
						if (index === slider.activeIndex) {
							$(item).find('video')[0].play();
						} else {
							$(item).find('video')[0].pause();
						}
					});
				});
			}
		},
	},
	cookie: {
		init($module) {
			if (localStorage.cookiesAgree !== 'yes') {
				setTimeout(() => {
					$module.fadeIn(250);
				}, 2000);
			}

			$module.find('.cookie__button button').on('click', (e) => {
				e.preventDefault();
				localStorage.cookiesAgree = 'yes';
				$module.fadeOut(250);
			});
		},
	},
	footer: {
		init($module) {
			app.scenes.push(new ScrollMagic.Scene({
				triggerElement: $module.find('.footer__trigger')[0],
				duration: window.outerHeight * (2 / 3),
				offset: 0,
				triggerHook: 1,
			}).setTween(gsap.to($module.find('.footer__bg')[0], 1, {
				rotate: 0,
				skewX: 0,
				skewY: 4.09,
			}, 0)).addTo(app.controller));
		},
	},
	suspended: {
		init($module) {
			let interval = null;

			let date = new Date($module.data('date'));

			let $timer = $module.find('.js-timer');

			let setTimer = () => {
				let curDate = new Date();

				let diff = date.getTime() - curDate.getTime();

				if (diff > 0) {
					let obj = {
						'{m}': Math.floor(diff / 1000 % 3600 / 60),
						'{h}': Math.floor(diff / 1000 / 3600 % 24),
						'{d}': Math.floor(diff / 1000 / 3600 / 24),
					}

					$timer.text($timer.data('template').replace(/{m}|{h}|{d}/gi, (matched) => obj[matched]));
				} else {
					clearInterval(interval);
				}
			}

			interval = setInterval(setTimer, 30000);
			setTimer();
		},
	},
};

app.global.init();

window.initApps = () => {
	$('[data-app]').each((index, item) => {
		let $this = $(item);
		let split = $this.data('app').split('||');

		$.each(split, (appIndex, appName) => {
			let appNameCamel = false;

			if (appName.indexOf('-') !== -1) {
				appNameCamel = appName.replace(/(-|\s)(\S)/ug, (m) => m.toUpperCase()).replace(/-/ug, '');
			}

			if (app[appName] && $this.data(`${appName}-init`) !== true) {
				app[appName].init($this);
				$this.data(`${appName}-init`, true);
			} else if (app[appNameCamel] && $this.data(`${appName}-init`) !== true) {
				app[appNameCamel].init($this);
				$this.data(`${appName}-init`, true);
			}
		});
	});
};

initApps();



window.addEventListener('scroll', initAnimate)

function initAnimate () {
	if (pageYOffset > 0) {
		const scriptPath = location.hostname === 'localhost' ? '/js/' : '/frontend/build/js/';
		let animate = document.createElement( 'script' );
		animate.type = 'module';
		document.body.appendChild( animate );
		animate.src = `${scriptPath}script.js`
		window.removeEventListener('scroll', initAnimate)
	}
}

